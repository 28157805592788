import TagManager from './packages/react-gtm-module/TagManager';

export const GTM_CATEGORY_INSCRIPTION = 'Inscription';
export const GTM_CATEGORY_CONNEXION = 'Connexion';
export const GTM_CATEGORY_CLASSE = 'Classes';
export const GTM_CATEGORY_MODULES = 'Modules';
export const GTM_CATEGORY_ACTIVITES = 'Activités';
export const GTM_CATEGORY_PISTES = 'Pistes';
export const GTM_CATEGORY_ECLAIREURS = 'Eclaireurs';
export const GTM_CATEGORY_LYCEENS = 'Lycéens';
export const GTM_CATEGORY_KIT_DE_SURVIE = 'Kit de survie';
export const GTM_CATEGORY_RECHERCHE = 'Recherche';
export const GTM_CATEGORY_ECHANGES = 'Echanges';
export const GTM_CATEGORY_FILTERS = 'Filtres';
export const GTM_CATEGORY_COMPTE = 'Compte';
export const GTM_CATEGORY_PROFIL = 'Profil';
export const GTM_CATEGORY_FAVORIS = 'Favoris';
export const GTM_CATEGORY_LANDING_PAGES = 'Landing Pages';
export const GTM_CATEGORY_QUESTIONNAIRE = 'Questionnaire';
export const GTM_CATEGORY_RESSOURCES = 'Ressources';

export const GTM_ACTION_INSCRIPTION = 'Inscription';
export const GTM_ACTION_INSCRIPTION_REUSSIE = 'Inscription Réussie';
export const GTM_ACTION_INSCRIPTION_PROF = 'Inscription Prof';
export const GTM_ACTION_INSCRIPTION_LYCEEN = 'Inscription Lycéen';
export const GTM_ACTION_INSCRIPTION_LYCEEN_ETAPE_1 =
  'Inscription Lycéen étape 1';

export const GTM_ACTION_QUESTION_1 = 'Question 1';
export const GTM_ACTION_QUESTION_2 = 'Question 2';
export const GTM_ACTION_QUESTION_3 = 'Question 3';
export const GTM_ACTION_QUESTION_4 = 'Question 4';
export const GTM_ACTION_QUESTION_5 = 'Question 5';
export const GTM_ACTION_QUESTION_6 = 'Question 6';
export const GTM_ACTION_QUESTION_7 = 'Question 7';
export const GTM_ACTION_QUESTION_8 = 'Question 8';
export const GTM_ACTION_QUESTION_9 = 'Question 9';
export const GTM_ACTION_QUESTIONNAIRE_TERMINE = 'Questionnaire terminé';
export const GTM_ACTION_VOIR_PISTES = 'Voir plus de pistes';
export const GTM_ACTION_ACCORD_CLASSEMENT = 'Accord classement';

export const GTM_ACTION_VOIR_RUBRIQUE = 'Voir rubrique';

export const GTM_ACTION_CONSULTATION_RESSOURCE = 'Consultation ressource';
export const GTM_ACTION_TELECHARGEMENT_RESSOURCE = 'Téléchargement ressource';

export const GTM_ACTION_INSCRIPTION_ECLAIREUR = 'Inscription Éclaireur';
export const GTM_ACTION_INSCRIPTION_ECLAIREUR_2 =
  'Inscription Éclaireur étape 2';
export const GTM_ACTION_INSCRIPTION_ECLAIREUR_TERMINEE =
  'Inscription Éclaireur terminée';
export const GTM_ACTION_QUITTER_CLASSE_LYCEEN = 'Quitter classe Lycéen';

export const GTM_ACTION_CONNEXION = 'Connexion';
export const GTM_ACTION_CONNEXION_SUCCES = 'Connexion réussie';

export const GTM_ACTION_ACTIVATION_MODULE = 'Activation module';
export const GTM_ACTION_DESACTIVATION_MODULE = 'Désactivation module';

export const GTM_ACTION_DEMARRER_ACTIVITE_LYCEEN = 'Démarrer activité lycéen';
export const GTM_ACTION_TERMINER_ACTIVITE_LYCEEN = 'Terminer activité lycéen';
export const GTM_ACTION_DEMARRER_ACTIVITE_PROF = 'Démarrer activité prof';
export const GTM_ACTION_TERMINER_ACTIVITE_PROF = 'Terminer activité prof';

export const GTM_ACTION_OUVERTURE_RECHERCHE = 'Ouverture recherche';
export const GTM_ACTION_RECHERCHE_SELECTION_LYCEEN = 'Sélection lycéen';
export const GTM_ACTION_RECHERCHE_SELECTION_ACTIVITE = 'Sélection activité';
export const GTM_ACTION_RECHERCHE_SELECTION_PISTE = 'Sélection piste';
export const GTM_ACTION_RECHERCHE_SELECTION_ARTICLE = 'Sélection article';
export const GTM_ACTION_RECHERCHE = 'Recherche';
export const GTM_ACTION_RECHERCHE_AUCUN_RESULTAT = 'Aucun résultat';

export const GTM_ACTION_INVITATION_LYCEEN = 'Invitation Lycéen';
export const GTM_ACTION_INVITATION_PROF = 'Invitation Prof';

export const GTM_ACTION_VOIR_ELEVES = 'Voir mes élèves';
export const GTM_ACTION_DEBLOQUER_DES_MODULES = 'Débloquer des modules';

export const GTM_ACTION_MISE_EN_FAVORIS = 'Mise en favoris';
export const GTM_ACTION_FILTRE_FAVORIS = 'Filtre Mise en favoris';

export const GTM_ACTION_ADD_NUMBER = 'Ajout numéro SMS';
export const GTM_ACTION_PANNE_INSPIRATION = "En panne d'inspiration";
export const GTM_ACTION_PANNE_INSPIRATION_PAGE_ECLAIREURS =
  'A cliqué sur En panne d’inspiration dans la page Eclaireurs';
export const GTM_ACTION_SEND_MESSAGE = 'Message envoyé';
export const GTM_ACTION_OPEN_CHAT = 'Ouvert chat';
export const GTM_ACTION_USE_ECLAIREUR_FILTER = 'Filtre Eclaireur';
export const GTM_ACTION_USE_PISTE_FILTER = 'Filtre Piste';
export const GTM_ACTION_EDIT_PROFILE = 'Edition profil';
export const GTM_ACTION_EXPLORE_COMPLETED = 'Explore completed';
export const GTM_ACTION_SIGNUP_COMPLETED = 'Signup completed';
export const GTM_ACTION_LYCEEN_ECHANGE = 'Lyceen echange';
export const GTM_ACTION_ECLAIREUR_ECHANGE = 'Eclaireur echange';
export const GTM_ACTION_ADD_NUMBER_ECLAIREUR = 'Eclaireur ajout numéro SMS';

export const GTM_ACTION_ADD_CLASSE = 'Ajouter Classe';
export const GTM_ACTION_REJOINDRE_CLASSE = 'Rejoindre Classe';
export const GTM_ACTION_CREATION_CLASSE = 'Création Classe';

export const GTM_ACTION_ECLAIREUR_EDIT_PROFIL = 'Eclaireur edition profil';
export const GTM_ACTION_ECLAIREUR_PROFIL_COMPLETE = 'Eclaireur profil complété';

export const GTM_ACTION_LANDING_PAGE_PRINCIPALE = 'Landing page principale';
export const GTM_ACTION_LANDING_PAGE_PROF = 'Landing page prof';
export const GTM_ACTION_LANDING_PAGE_LYCEEN = 'Landing page lycéen';
export const GTM_ACTION_LANDING_PAGE_STUDENT = 'Landing page éclaireur';

export const GTM_ACTION_CLICK_TEXT = 'ClickText';

export const pushAsyncPageView = () =>
  TagManager.dataLayer({
    dataLayer: { event: 'Async Page View' },
  });

export const pushEvent = (
  category: string = undefined,
  action: string = undefined,
  label: string = undefined,
  value: unknown = undefined,
  event?: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event ?? 'GAEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    },
  });
};
